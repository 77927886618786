/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import SEO from '../components/seo'

const TermsOfUse = () => (
  <>
    <SEO title="Termos de Serviço - Immortalis" />
    <section className="mw8-ns center pa3 mt4">
      <h1 className="fw5 mb4">Termos de Serviço Immortalis</h1>
      <h2 className="f4">Visão Geral</h2>
      <p className="lh-copy f5 mb3">
        Esse site é operado pelo Immortalis. Em todo o site, os termos “nós”, “nos” e “nosso” se
        referem ao Immortalis. O Immortalis proporciona esse site, incluindo todas as informações,
        ferramentas e serviços disponíveis deste site para você, o usuário, com a condição da sua
        aceitação de todos os termos, condições, políticas e avisos aqui declarados.
      </p>
      <p className="lh-copy f5 mb3">
        Ao visitar nosso site e/ou comprar alguma coisa no nosso site, você está utilizando nossos
        “Serviços”. Consequentemente, você concorda com os seguintes termos e condições (“Termos de
        serviço”, “Termos”), incluindo os termos e condições e políticas adicionais mencionados
        documento e/ou disponíveis por hyperlink. Esses Termos de serviço se aplicam a todos os
        usuários do site, incluindo, sem limitação, os usuários que são navegadores, fornecedores,
        clientes e/ou contribuidores de conteúdo.
      </p>
      <p className="lh-copy f5 mb3">
        Por favor, leia esses Termos de serviço cuidadosamente antes de acessar ou utilizar o nosso
        site. Ao acessar ou usar qualquer parte do site, você concorda com os Termos de serviço. Se
        você não concorda com todos os termos e condições desse acordo, então você não pode acessar
        o site ou usar quaisquer serviços. Se esses Termos de serviço são considerados uma oferta, a
        aceitação é expressamente limitada a esses Termos de serviço.
      </p>
      <p className="lh-copy f5 mb3">
        Quaisquer novos recursos ou ferramentas que forem adicionados ao site atual também devem
        estar sujeitos aos Termos de serviço. Você pode revisar a versão mais atual dos Termos de
        serviço quando quiser nesta página. Reservamos o direito de atualizar, alterar ou trocar
        qualquer parte desses Termos de serviço ao publicar atualizações e/ou alterações no nosso
        site. É sua responsabilidade verificar as alterações feitas nesta página periodicamente. Seu
        uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui aceitação
        de tais alterações.
      </p>
      <h2 className="f4">Seção 1 - Termos do Site</h2>
      <p className="lh-copy f5 mb3">
        Ao concordar com os Termos de serviço, você confirma que você é maior de idade em seu estado
        ou província de residência e que você nos deu seu consentimento para permitir que qualquer
        um dos seus dependentes menores de idade usem esse site.
      </p>
      <p className="lh-copy f5 mb3">
        Você não deve usar nossos produtos para qualquer fim ilegal ou não autorizado. Você também
        não pode, ao usufruir deste Serviço, violar quaisquer leis em sua jurisdição (incluindo, mas
        não limitado, a leis de direitos autorais).
      </p>
      <p className="lh-copy f5 mb3">
        Você não deve transmitir nenhum vírus ou qualquer código de natureza destrutiva.
      </p>
      <p className="lh-copy f5 mb3">
        Violar qualquer um dos Termos tem como consequência a rescisão imediata dos seus Serviços.
      </p>
      <h2 className="f4">Seção 2 - Condições Gerais</h2>
      <p className="lh-copy f5 mb3">
        Reservamos o direito de recusar o serviço a qualquer pessoa por qualquer motivo a qualquer
        momento.
      </p>
      <p className="lh-copy f5 mb3">
        Você entende que o seu conteúdo (não incluindo informações de cartão de crédito), pode ser
        transferido sem criptografia e pode: (a) ser transmitido por várias redes; e (b) sofrer
        alterações para se adaptar e se adequar às exigências técnicas de conexão de redes ou
        dispositivos. As informações de cartão de crédito sempre são criptografadas durante a
        transferência entre redes.
      </p>
      <p className="lh-copy f5 mb3">
        Você concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer
        parte do Serviço, uso do Serviço, acesso ao Serviço, ou qualquer contato no site através do
        qual o serviço é fornecido, sem nossa permissão expressa por escrito.
      </p>
      <p className="lh-copy f5 mb3">
        Os títulos usados nesse acordo são incluídos apenas por conveniência e não limitam ou afetam
        os Termos.
      </p>
      <h2 className="f4">Seção 3 - Precisão, Integridade e Atualização das Informações</h2>
      <p className="lh-copy f5 mb3">
        Não somos responsáveis por informações disponibilizadas nesse site que não sejam precisas,
        completas ou atuais. O material desse site é fornecido apenas para fins informativos e não
        deve ser usado como a única base para tomar decisões sem consultar fontes de informações
        primárias, mais precisas, mais completas ou mais atuais. Qualquer utilização do material
        desse site é por sua conta e risco.
      </p>
      <p className="lh-copy f5 mb3">
        Esse site pode conter certas informações históricas. As informações históricas podem não ser
        atuais e são fornecidas apenas para sua referência. Reservamos o direito de modificar o
        conteúdo desse site a qualquer momento, mas nós não temos obrigação de atualizar nenhuma
        informação em nosso site. Você concorda que é de sua responsabilidade monitorar alterações
        no nosso site.
      </p>
      <h2 className="f4">Seção 4 - Modificações do Serviço e Preços</h2>
      <p className="lh-copy f5 mb3">
        Os preços dos nossos produtos e serviços são sujeitos a alterações sem notificação.
      </p>
      <p className="lh-copy f5 mb3">
        Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (ou
        qualquer parte ou conteúdo do mesmo) sem notificação em qualquer momento.
      </p>
      <p className="lh-copy f5 mb3">
        Não nos responsabilizados por você ou por qualquer terceiro por qualquer modificação,
        alteração de preço, suspensão ou descontinuação do Serviço.
      </p>
      <h2 className="f4">Seção 5 - Produtos ou Serviços (caso aplicável)</h2>
      <p className="lh-copy f5 mb3">
        Reservamos o direito, mas não somos obrigados, a limitar as vendas de nossos produtos ou
        Serviços para qualquer pessoa, região geográfica ou jurisdição. Podemos exercer esse direito
        conforme o caso. Reservamos o direito de limitar as quantidades de quaisquer produtos ou
        serviços que oferecemos. Todas as descrições de produtos ou preços de produtos são sujeitos
        a alteração a qualquer momento sem notificação, a nosso critério exclusivo. Reservamos o
        direito de descontinuar qualquer produto a qualquer momento. Qualquer oferta feita por
        qualquer produto ou serviço nesse site é nula onde for proibido por lei.
      </p>
      <p className="lh-copy f5 mb3">
        Não garantimos que a qualidade de quaisquer produtos, serviços, informações ou outros
        materiais comprados ou obtidos por você vão atender às suas expectativas, ou que quaisquer
        erros no Serviço serão corrigidos.
      </p>
      <h2 className="f4">Seção 6 - Precisão de informações de faturamento e conta</h2>
      <p className="lh-copy f5 mb3">
        Reservamos o direito de recusar qualquer pedido que você nos fizer. Tais restrições podem
        incluir pedidos feitos na mesma conta de cliente, no mesmo cartão de crédito, e/ou pedidos
        que usam a mesma fatura e/ou endereço de envio. Caso façamos alterações ou cancelemos um
        pedido, pode ser que o notifiquemos por e-mail e/ou endereço/número de telefone de
        faturamento fornecidos no momento que o pedido foi feito. Reservamos o direito de limitar ou
        proibir pedidos que, a nosso critério exclusivo, parecem ser feitos de má fé.
      </p>
      <p className="lh-copy f5 mb3">
        Você concorda em fornecer suas informações de conta e compra completas para todas as compras
        feitas em nosso site. Você concorda em atualizar prontamente sua conta e outras informações,
        incluindo seu e-mail, números de cartão de crédito e datas de validade, para que possamos
        completar suas transações e contatar você quando preciso.
      </p>
      <h2 className="f4">Seção 7 - Ferramentas opcionais</h2>
      <p className="lh-copy f5 mb3">
        Podemos te dar acesso a ferramentas de terceiros que não monitoramos e nem temos qualquer
        controle.
      </p>
      <p className="lh-copy f5 mb3">
        Você reconhece e concorda que nós fornecemos acesso a tais ferramentas ”como elas são” e
        “conforme a disponibilidade” sem quaisquer garantias, representações ou condições de
        qualquer tipo e sem qualquer endosso. Não nos responsabilizamos de forma alguma pelo seu uso
        de ferramentas opcionais de terceiros.
      </p>
      <p className="lh-copy f5 mb3">
        Qualquer uso de ferramentas opcionais oferecidas através do site é inteiramente por sua
        conta e risco e você se familiarizar e aprovar os termos das ferramentas que são fornecidas
        por fornecedor(es) terceiro(s).
      </p>
      <p className="lh-copy f5 mb3">
        Também podemos, futuramente, oferecer novos serviços e/ou recursos através do site
        (incluindo o lançamento de novas ferramentas e recursos). Tais recursos e/ou serviços novos
        também devem estar sujeitos a esses Termos de serviço.
      </p>
      <h2 className="f4">Seção 8 - Links de Terceiros</h2>
      <p className="lh-copy f5 mb3">
        Certos produtos, conteúdos e serviços disponíveis pelo nosso Serviço podem incluir materiais
        de terceiros.
      </p>
      <p className="lh-copy f5 mb3">
        Os links de terceiros nesse site podem te direcionar para sites de terceiros que não são
        afiliados a nós. Não nos responsabilizamos por examinar ou avaliar o conteúdo ou precisão.
        Não garantimos e nem temos obrigação ou responsabilidade por quaisquer materiais ou sites de
        terceiros, ou por quaisquer outros materiais, produtos ou serviços de terceiros.
      </p>
      <p className="lh-copy f5 mb3">
        Não somos responsáveis por quaisquer danos ou prejuízos relacionados com a compra ou uso de
        mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão
        com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas de
        terceiros e certifique-se que você as entende antes de efetuar qualquer transação. As
        queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser
        direcionadas ao terceiro.
      </p>
      <h2 className="f4">Seção 9 - Comentários, Feedback etc. do Usuário</h2>
      <p className="lh-copy f5 mb3">
        Se, a nosso pedido, você enviar certos itens específicos (por exemplo, participação em um
        concurso), ou sem um pedido nosso, você enviar ideias criativas, sugestões, propostas,
        planos, ou outros materiais, seja online, por e-mail, pelo correio, ou de outra forma (em
        conjunto chamados de &quot;comentários&quot;), você concorda que podemos, a qualquer
        momento, sem restrição, editar, copiar, publicar, distribuir, traduzir e de outra forma usar
        quaisquer comentários que você encaminhar para nós. Não nos responsabilizamos por: (1)
        manter quaisquer comentários em sigilo; (2) indenizar por quaisquer comentários; ou (3)
        responder quaisquer comentários.
      </p>
      <p className="lh-copy f5 mb3">
        Podemos, mas não temos a obrigação, de monitorar, editar ou remover conteúdo que nós
        determinamos a nosso próprio critério ser contra a lei, ofensivo, ameaçador, calunioso,
        difamatório, pornográfico, obsceno ou censurável ou que viole a propriedade intelectual de
        terceiros ou estes Termos de serviço.
      </p>
      <p className="lh-copy f5 mb3">
        Você concorda que seus comentários não violarão qualquer direito de terceiros, incluindo
        direitos autorais, marcas registradas, privacidade, personalidade ou outro direito pessoal
        ou de propriedade. Você concorda que os seus comentários não vão conter material
        difamatório, ilegal, abusivo ou obsceno. Eles também não conterão nenhum vírus de computador
        ou outro malware que possa afetar a operação do Serviço ou qualquer site relacionado. Você
        não pode usar um endereço de e-mail falso, fingir ser alguém diferente de si mesmo, ou de
        outra forma enganar a nós ou terceiros quanto à origem de quaisquer comentários. Você é o
        único responsável por quaisquer comentários que você faz e pela veracidade deles. Nós não
        assumimos qualquer responsabilidade ou obrigação por quaisquer comentários publicados por
        você ou por qualquer terceiro.
      </p>
      <h2 className="f4">Seção 10 - Informações Pessoais</h2>O envio de suas informações pessoais
      através do site é regido pela nossa Política de privacidade. Ver nossa&nbsp;
      <a href="/politica-de-privacidade" className="black">
        Política de privacidade.
      </a>
      <h2 className="f4">Seção 11 - Erros, Imprecisões e Omissões</h2>
      <p className="lh-copy f5 mb3">
        Ocasionalmente, pode haver informações no nosso site ou no Serviço que contém erros
        tipográficos, imprecisões ou omissões que possam relacionar-se a descrições de produtos,
        preços, promoções, ofertas, taxas de envio do produto, o prazo de envio e disponibilidade.
        Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou
        atualizar informações ou cancelar encomendas caso qualquer informação no Serviço ou em
        qualquer site relacionado seja imprecisa, a qualquer momento e sem aviso prévio (até mesmo
        depois de você ter enviado o seu pedido).
      </p>
      <p className="lh-copy f5 mb3">
        Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer informações no Serviço
        ou em qualquer site relacionado, incluindo, sem limitação, a informações sobre preços,
        exceto conforme exigido por lei. Nenhuma atualização específica ou data de atualização no
        Serviço ou em qualquer site relacionado, deve ser utilizada para indicar que todas as
        informações do Serviço ou em qualquer site relacionado tenham sido modificadas ou
        atualizadas.
      </p>
      <h2 className="f4">Seção 12 - Usos proibidos</h2>
      <p className="lh-copy f5 mb3">
        Além de outras proibições, conforme estabelecido nos Termos de serviço, você está proibido
        de usar o site ou o conteúdo para: (a) fins ilícitos; (b) solicitar outras pessoas a
        realizar ou participar de quaisquer atos ilícitos; (c) violar quaisquer regulamentos
        internacionais, provinciais, estaduais ou federais, regras, leis ou regulamentos locais; (d)
        infringir ou violar nossos direitos de propriedade intelectual ou os direitos de propriedade
        intelectual de terceiros; (e) para assediar, abusar, insultar, danificar, difamar, caluniar,
        depreciar, intimidar ou discriminar com base em gênero, orientação sexual, religião, etnia,
        raça, idade, nacionalidade ou deficiência; (f) apresentar informações falsas ou enganosas;
        (g) fazer o envio ou transmitir vírus ou qualquer outro tipo de código malicioso que será ou
        poderá ser utilizado para afetar a funcionalidade ou operação do Serviço ou de qualquer site
        relacionado, outros sites, ou da Internet; (h) coletar ou rastrear as informações pessoais
        de outras pessoas; (i) para enviar spam, phishing, pharm, pretext, spider, crawl, ou scrape;
        (j) para fins obscenos ou imorais; ou (k) para interferir ou contornar os recursos de
        segurança do Serviço ou de qualquer site relacionado, outros sites, ou da Internet.
        Reservamos o direito de rescindir o seu uso do Serviço ou de qualquer site relacionado por
        violar qualquer um dos usos proibidos.
      </p>
      <h2 className="f4">
        Seção 13 - Isenção de responsabilidade de garantias; Limitação de responsabilidade
      </h2>
      <p className="lh-copy f5 mb3">
        Nós não garantimos, representamos ou justificamos que o seu uso do nosso serviço será
        pontual, seguro, sem erros ou interrupções.
      </p>
      <p className="lh-copy f5 mb3">
        Não garantimos que os resultados que possam ser obtidos pelo uso do serviço serão precisos
        ou confiáveis.
      </p>
      <p className="lh-copy f5 mb3">
        Você concorda que de tempos em tempos, podemos remover o serviço por períodos indefinidos de
        tempo ou cancelar a qualquer momento, sem te notificar.
      </p>
      <p className="lh-copy f5 mb3">
        Você concorda que o seu uso ou incapacidade de usar o serviço é por sua conta e risco. O
        serviço e todos os produtos e serviços entregues através do serviço são, exceto conforme
        declarado por nós) fornecidos sem garantia e conforme a disponibilidade para seu uso, sem
        qualquer representação, garantias ou condições de qualquer tipo, expressas ou implícitas,
        incluindo todas as garantias implícitas ou condições de comercialização, quantidade,
        adequação a uma finalidade específica, durabilidade, título, e não violação.
      </p>
      <p className="lh-copy f5 mb3">
        Em nenhuma circunstância o Immortalis, nossos diretores, oficiais, funcionários, afiliados,
        agentes, contratantes, estagiários, fornecedores, prestadores de serviços ou licenciadores
        serão responsáveis por qualquer prejuízo, perda, reclamação ou danos diretos, indiretos,
        incidentais, punitivos, especiais ou consequentes de qualquer tipo, incluindo, sem
        limitação, lucros cessantes, perda de receita, poupanças perdidas, perda de dados, custos de
        reposição, ou quaisquer danos semelhantes, seja com base em contrato, ato ilícito (incluindo
        negligência), responsabilidade objetiva ou de outra forma, decorrentes do seu uso de
        qualquer um dos serviços ou quaisquer produtos adquiridos usando o serviço, ou para qualquer
        outra reclamação relacionada de alguma forma ao seu uso do serviço ou qualquer produto,
        incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer conteúdo, ou qualquer
        perda ou dano de qualquer tipo como resultado do uso do serviço ou qualquer conteúdo (ou
        produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo se
        alertado de tal possibilidade. Como alguns estados ou jurisdições não permitem a exclusão ou
        a limitação de responsabilidade por danos consequentes ou incidentais, em tais estados ou
        jurisdições, a nossa responsabilidade será limitada à extensão máxima permitida por lei.
      </p>
      <h2 className="f4">Seção 14 - Indenização</h2>
      <p className="lh-copy f5 mb3">
        Você concorda em indenizar, defender e isentar Immortalis e nossos subsidiários, afiliados,
        parceiros, funcionários, diretores, agentes, contratados, licenciantes, prestadores de
        serviços, subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação
        ou demanda, incluindo honorários de advogados, por quaisquer terceiros devido à violação
        destes Termos de serviço ou aos documentos que incorporam por referência, ou à violação de
        qualquer lei ou os direitos de um terceiro.
      </p>
      <h2 className="f4">Seção 15 - Independência</h2>
      <p className="lh-copy f5 mb3">
        No caso de qualquer disposição destes Termos de serviço ser considerada ilegal, nula ou
        ineficaz, tal disposição deve, contudo, ser aplicável até ao limite máximo permitido pela
        lei aplicável, e a porção inexequível será considerada separada desses Termos de serviço.
        Tal determinação não prejudica a validade e aplicabilidade de quaisquer outras disposições
        restantes.
      </p>
      <h2 className="f4">Seção 16 - Rescisão</h2>
      <p className="lh-copy f5 mb3">
        As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem
        continuar após a rescisão deste acordo para todos os efeitos.
      </p>
      <p className="lh-copy f5 mb3">
        Estes Termos de Serviço estão em vigor, a menos que seja rescindido por você ou por nós.
        Você pode rescindir estes Termos de serviço a qualquer momento, notificando-nos que já não
        deseja utilizar os nossos serviços, ou quando você deixar de usar o nosso site.
      </p>
      <p className="lh-copy f5 mb3">
        Se em nosso critério exclusivo você não cumprir com qualquer termo ou disposição destes
        Termos de serviço, nós também podemos rescindir este contrato a qualquer momento sem aviso
        prévio e você ficará responsável por todas as quantias devidas até a data da rescisão;
        também podemos lhe negar acesso a nossos Serviços (ou qualquer parte deles).
      </p>
      <h2 className="f4">Seção 17 - Acordo integral</h2>
      <p className="lh-copy f5 mb3">
        Caso não exerçamos ou executemos qualquer direito ou disposição destes Termos de serviço,
        isso não constituirá uma renúncia de tal direito ou disposição.
      </p>
      <p className="lh-copy f5 mb3">
        Estes Termos de serviço e quaisquer políticas ou normas operacionais postadas por nós neste
        site ou no que diz respeito ao serviço constituem a totalidade do acordo entre nós. Estes
        termos regem o seu uso do Serviço, substituindo quaisquer acordos anteriores ou
        contemporâneos, comunicações e propostas, sejam verbais ou escritos, entre você e nós
        (incluindo, mas não limitado a quaisquer versões anteriores dos Termos de serviço).
      </p>
      <p className="lh-copy f5 mb3">
        Quaisquer ambiguidades na interpretação destes Termos de serviço não devem ser interpretadas
        contra a parte que os redigiu.
      </p>
      <h2 className="f4">Seção 18 - Alterações dos Termos de Serviço</h2>
      <p className="lh-copy f5 mb3">
        Você pode rever a versão mais atual dos Termos de serviço a qualquer momento nessa página.
      </p>
      <p className="lh-copy f5 mb3">
        Reservamos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte
        destes Termos de serviço ao publicar atualizações e alterações no nosso site. É sua
        responsabilidade verificar nosso site periodicamente. Seu uso contínuo ou acesso ao nosso
        site ou ao Serviço após a publicação de quaisquer alterações a estes Termos de serviço
        constitui aceitação dessas alterações.
      </p>
      <h2 className="f4">Seção 19 - Informações de contato</h2>
      <p className="lh-copy f5 mb3">
        As perguntas sobre os Termos de serviço devem ser enviadas para nós através do
        admin@immortalis.com.br.
      </p>
    </section>
  </>
)

export default TermsOfUse
